import {createSlice} from "@reduxjs/toolkit";

export type TopGainer = {
    address: string,
    symbol: string,
    name: string,
    rise24: number,
}

type MarketState = {
    gainers: TopGainer[],
}

const initialState: MarketState|null = null;

const slice = createSlice({
    name: 'market',
    initialState,
    reducers: {
    },
})

export default slice