import React from 'react';
import {Alert, Container} from "@mui/material";
import {selectAuthToken} from "./Feature/Auth/authSlice";
import {useAppSelector} from "./DataProvider/store";
import {useTelegramAuthMutation} from "./Feature/Auth/authApi";
import Preloader from "./Component/Preloader";
import {useGetMyProfileQuery} from "./Feature/Profile/profileApi";
import MarketScreen from "./Feature/Market/MarketScreen";

export function FatalErrorScreen(params: {text: string, tryAgain?: true}) {
    return (
        <Container sx={{ display: 'flex', justifyContent: "center", height: "100vh", alignItems: "center"  }}>
            <Alert severity="error">{ params.text }</Alert>
        </Container>
    );
}

let authInProcess = false;

export default function TelegramApp() {
    const tgUserId = window.Telegram.WebApp.initDataUnsafe.user?.id;

    const authToken = useAppSelector((state) => selectAuthToken(state));
    const [auth, {status}] = useTelegramAuthMutation();

    const {data: userData} = useGetMyProfileQuery();

    if (tgUserId === undefined) {
        return (<FatalErrorScreen text={"Invalid environment"} />);
    }

    switch (status) {
        case "pending":
            return (
                <Container sx={{ display: 'flex', justifyContent: "center", height: "100vh", alignItems: "center"  }}>
                    <Preloader />
                </Container>
            );
        case "rejected":
            authInProcess = false;
            return (<FatalErrorScreen text={"Unable to connect server"} tryAgain />);
        case "fulfilled":
            authInProcess = false;
            break;
        case "uninitialized":
            if (!authToken && !authInProcess) {
                authInProcess = true;
                auth(window.Telegram.WebApp.initData);
            }
    }

    if (!userData || !userData.status || userData.status !== "active") {
        return (<FatalErrorScreen text={"Private area"} />);
    }

    return (
        <Container sx={{ }}>
            <MarketScreen />
        </Container>
    );
}
