import baseApi from "../../DataProvider/baseApi";
import {TopGainer} from "./marketSlice";

export interface GetTopGainersResponse {
    list: TopGainer[],
}

const api = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getTopGainers: builder.query<GetTopGainersResponse, void>({
            query: () => ({
                url: '/market/get-top-gainers',
                method: 'GET',
            }),
        }),
    }),
});

export default api

export const { useGetTopGainersQuery } = api