import {Avatar, Container, List, ListItem, ListItemAvatar, ListItemText, Typography} from "@mui/material";
import React from "react";
import {useGetTopGainersQuery} from "./marketApi";
import Preloader from "../../Component/Preloader";

export default function MarketScreen() {
    const {data} = useGetTopGainersQuery();

    if (!data) {
        return (
            <Container sx={{ display: 'flex', justifyContent: "center", height: "100vh", alignItems: "center"  }}>
                <Preloader />
            </Container>
        );
    }

    return (
        <>
            <Typography variant="h6" sx={{ mt: 2, }}>Top gainers:</Typography>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', mt: 2 }}>
                {
                    data.list.map((item) => {
                        return (
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar alt={item.symbol}>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={item.symbol.toUpperCase()} secondary={<Typography sx={{fontWeight: 'bold', color: '#266e26'}}>↑ {Math.round(item.rise24 * 100)}%</Typography>} />
                            </ListItem>
                        );
                    })
                }
            </List>
        </>
    );
}